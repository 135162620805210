import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { stripParagraphTag } from '@util/transform';
import { Close } from './close';
import Timer from './timer';
import { Band, BandTitle, Wrapper } from './styled';

interface props {
  href: string;
  isBlank: boolean;
  backgroundColor: string;
  text: string;
  endDate: string;
  closedShow: string;
  closeColor: string;
  onClickRemove: MouseEventHandler;
}

export const BandBanner: React.FC<props> = ({
  onClickRemove,
  href,
  isBlank,
  backgroundColor,
  text,
  endDate,
  closedShow,
  closeColor,
  ...rest
}) => {
  const openTargetWindow = isBlank ? '_blank' : '';
  const ref = useRef(null);
  const timerRef = useRef(null);
  const [isShowTimer, setIsShowTimer] = useState(true);

  const handleResize = () => {
    if (!timerRef.current) return;

    const timerRect = timerRef.current.getBoundingClientRect();
    const bannerRect = ref.current.getBoundingClientRect();

    // 배너 텍스트와 타이머가 충돌하면
    if (timerRect.x - 20 < bannerRect.x + bannerRect.width && timerRect.x + 20 + timerRect.width > bannerRect.x) setIsShowTimer(false);
    else setIsShowTimer(true);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrapper {...rest}>
      <a href={href || '#'} target={openTargetWindow} rel="noreferrer" className="top__banner">
        <Band color={backgroundColor}>
          <BandTitle color={closeColor}>
            <div ref={ref}>{parse(stripParagraphTag(text))}</div>
          </BandTitle>
          {endDate && closedShow !== 'none' ? (
            <Timer
              ref={timerRef}
              isShow={isShowTimer}
              targetDate={endDate}
              displayType={closedShow}
              color={closeColor}
            ></Timer>
          ) : null}
          <Close
            buttonColor={closeColor}
            onClick={(e) => {
              onClickRemove(e);
            }}
          />
        </Band>
      </a>
    </Wrapper>
  );
};
