import Image from 'next/legacy/image';
import styled from 'styled-components';

export const AutoNextImage = styled(Image)`
  width: 100% !important;
  position: relative !important;
  height: auto !important;
`;

export const AutoNextImageContainer = styled.div`
  > span {
    position: unset !important;
    height: 100%;
  }
`;
